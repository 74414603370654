import React from "react"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { Md, MdBox, MdColumn } from "../../components/vlayout/MasterDetail"

export default function MasterDetailPage() {
  return (
    <DebugLayout flush dontScroll>
      <Md>
        <MdColumn>
          {"Velit ipsum dolore officia tempor laborum ut nostrud aliqua aliquip dolor sint adipisicing minim enim incididunt voluptate id minim deserunt tempor est dolor sint adipisicing minim enim incididunt voluptate id minim deserunt tempor est"
            .split(" ")
            .map((title, i) => (
              <MdBox key={i}>{title}</MdBox>
            ))}
        </MdColumn>
        <MdBox scroll>
          Velit ipsum dolore officia tempor laborum ut nostrud aliqua aliquip.
          Dolor sint adipisicing minim enim incididunt voluptate id minim
          deserunt aliqua occaecat ullamco dolor. Eiusmod nulla tempor sint
          ipsum qui labore est. Id dolor elit ex cupidatat. Enim et laboris
          fugiat est fugiat esse duis id laborum excepteur et ullamco qui. Esse
          nulla amet tempor est. Velit ipsum dolore officia tempor laborum ut
          nostrud aliqua aliquip. Dolor sint adipisicing minim enim incididunt
          voluptate id minim deserunt aliqua occaecat ullamco dolor. Eiusmod
          nulla tempor sint ipsum qui labore est. Id dolor elit ex cupidatat.
          Enim et laboris fugiat est fugiat esse duis id laborum excepteur et
          ullamco qui. Esse nulla amet tempor est. Velit ipsum dolore officia
          tempor laborum ut nostrud aliqua aliquip. Dolor sint adipisicing minim
          enim incididunt voluptate id minim deserunt aliqua occaecat ullamco
          dolor. Eiusmod nulla tempor sint ipsum qui labore est. Id dolor elit
          ex cupidatat. Enim et laboris fugiat est fugiat esse duis id laborum
          excepteur et ullamco qui. Esse nulla amet tempor est. Velit ipsum
          dolore officia tempor laborum ut nostrud aliqua aliquip. Dolor sint
          adipisicing minim enim incididunt voluptate id minim deserunt aliqua
          occaecat ullamco dolor. Eiusmod nulla tempor sint ipsum qui labore
          est. Id dolor elit ex cupidatat. Enim et laboris fugiat est fugiat
          esse duis id laborum excepteur et ullamco qui. Esse nulla amet tempor
          est. Velit ipsum dolore officia tempor laborum ut nostrud aliqua
          aliquip. Dolor sint adipisicing minim enim incididunt voluptate id
          minim deserunt aliqua occaecat ullamco dolor. Eiusmod nulla tempor
          sint ipsum qui labore est. Id dolor elit ex cupidatat. Enim et laboris
          fugiat est fugiat esse duis id laborum excepteur et ullamco qui. Esse
          nulla amet tempor est. Velit ipsum dolore officia tempor laborum ut
          nostrud aliqua aliquip. Dolor sint adipisicing minim enim incididunt
          voluptate id minim deserunt aliqua occaecat ullamco dolor. Eiusmod
          nulla tempor sint ipsum qui labore est. Id dolor elit ex cupidatat.
          Enim et laboris fugiat est fugiat esse duis id laborum excepteur et
          ullamco qui. Esse nulla amet tempor est. Velit ipsum dolore officia
          tempor laborum ut nostrud aliqua aliquip. Dolor sint adipisicing minim
          enim incididunt voluptate id minim deserunt aliqua occaecat ullamco
          dolor. Eiusmod nulla tempor sint ipsum qui labore est. Id dolor elit
          ex cupidatat. Enim et laboris fugiat est fugiat esse duis id laborum
          excepteur et ullamco qui. Esse nulla amet tempor est. Velit ipsum
          dolore officia tempor laborum ut nostrud aliqua aliquip. Dolor sint
          adipisicing minim enim incididunt voluptate id minim deserunt aliqua
          occaecat ullamco dolor. Eiusmod nulla tempor sint ipsum qui labore
          est. Id dolor elit ex cupidatat. Enim et laboris fugiat est fugiat
          esse duis id laborum excepteur et ullamco qui. Esse nulla amet tempor
          est.
        </MdBox>
      </Md>
    </DebugLayout>
  )
}
